$surface900: #191c1f;

:root {
  --static-video-height: 128px;
  --video-height: 128px;
  --video-width: auto;
  --video-cell-width: 320px;

  --bg-underlaying: #{$surface900};

  --elevation-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2),
    0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 0px 1px 1px rgba(255, 255, 255, 0.03) inset;
  --elevation-shadow-filter: drop-shadow(0 2px 0 rgba(0, 0, 0, 0.2));

  --shadow-button-default: 0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1px 0px 0px rgba(0, 0, 0, 0.2);

  --color-red: rgb(239, 17, 38);
}
