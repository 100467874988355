.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msg {
  h1 {
    font-family: "Roboto Mono", monospace;
    font-weight: 300;
    font-size: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #828f99;
    margin-bottom: 32px;
  }
}

.offSymbol {
  width: 110px;
  height: 57px;
  border-radius: 158px;
  background: #23282f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  font-family: "Roboto Mono", monospace;
  margin: auto auto 20px;

  &::before {
    width: 52px;
    line-height: 52px;
    text-align: center;
    display: block;
    background: #828f99;
    border-radius: 50%;
    content: "O";
    font-size: 32px;
    color: #23282f;
  }

  &::after {
    width: 52px;
    line-height: 52px;
    text-align: center;
    display: block;
    content: "I";
    font-size: 32px;
    color: #828f99;
    opacity: 0.5;
  }
}

.contact {
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #828f99;

  a {
    color: #fff;
    text-decoration: none;
  }
}

.footerLogo {
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;

  polygon {
    fill: #43828a;
  }
}

.footer {
  padding: 18px;

  span {
    font-size: 10px;
    color: #8c8c8d;
  }
}
